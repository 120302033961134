var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pictureads"},[(!_vm.imageList[0])?_c('div',{staticClass:"upload"},[_c('i',{staticClass:"iconfont icon-lunbotu"})]):_vm._e(),(_vm.imageList[0] && _vm.swiperType === 0)?_c('div',{staticClass:"type0",style:({
      'padding-left': _vm.datas.pageMargin + 'px',
      'padding-right': _vm.datas.pageMargin + 'px',
    })},_vm._l((_vm.imageList),function(item,index){return _c('div',{key:index,staticClass:"imgLis",style:({ 'margin-bottom': _vm.datas.imageMargin + 'px' })},[_c('a',{attrs:{"href":item.http.externalLink ? item.http.externalLink : 'javascript:;'}},[_c('img',{style:({ 'border-radius': _vm.datas.borderRadius + 'px' }),attrs:{"src":item.src + '?x-oss-process=image/resize,w_1280/quality,q_70',"draggable":"false"}})]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.text ? true : false),expression:"item.text ? true : false"}],staticClass:"title"},[_vm._v(_vm._s(item.text))])])}),0):_vm._e(),(
      (_vm.imageList[0] && _vm.swiperType === 1) ||
      _vm.swiperType === 2 ||
      _vm.swiperType === 3
    )?_c('div',{staticClass:"swiper-container"},[_c('div',{class:_vm.swiperType === 3 && _vm.imageList[0]
          ? 'type3 type1 swiper-wrapper type3H'
          : 'swiper-wrapper type1'},_vm._l((_vm.imageList),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('a',{attrs:{"href":item.http.externalLink ? item.http.externalLink : 'javascript:;'}},[_c('img',{style:({ 'border-radius': _vm.datas.borderRadius + 'px' }),attrs:{"src":item.src + '?x-oss-process=image/resize,w_1280/quality,q_60',"alt":"","draggable":"false"}})]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.text ? true : false),expression:"item.text ? true : false"}],staticClass:"title"},[_vm._v(_vm._s(item.text))])])}),0),_c('div',{staticClass:"swiper-pagination",staticStyle:{"color":"#007aff"}})]):_vm._e(),_vm._t("deles")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }