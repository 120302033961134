<template>
  <div class="page">
    <!-- 顶部销售名片 -->
    <div class="sale" v-if="service_card.show">
			<div v-if="firstImg" class="sale-bg" :style="{'backgroundImage': 'url('+firstImg+')'}"></div>
			<div class="sale-intro">
				<div style="position: relative;">
					<!-- <view class="sale-bg" :style="{'backgroundImage': 'url('+firstImg+')'}"></view> -->
					<!-- <u-avatar :src="sale.head" :text="sale.head?'':sale.name" size="large"></u-avatar> -->
          <van-image
            width="60"
            height="60"
            round
            :src="service_card.avatar_type == 1? articleData.employee.avatar : service_card.avatar_custom"
            style="vertical-align: middle;"
          />
				</div>
				<!-- <image :src="sale.head" mode="widthFix"></image> -->
				<div class="sale-intro-text">
					<div style="margin-bottom: 5px">{{ service_card.name_type == 1? articleData.employee.name : service_card.name_custom }}</div>
					<div>{{ service_card.company_name }}</div>
				</div>
			</div>
      <!-- 销售名片下方导航 -->
			<div class="sale-bar" v-if="service_card.btns && service_card.btns.length > 0">
				<div class="sale-bar-item" v-for="(item, index) in service_card.btns" :key="index" @click="clickHandle(item)">
          <template v-if="item.type == 'wxApp'">
            <wx-open-launch-weapp
              :username="item.data"
              style="position: absolute; width: 100%; height: 100%; top: 0;left: 0;"
            >
              <script type="text/wxtag-template">
                <view style="position: absolute; width: 100%; height: 100%; top: 0;left: 0;"></view>	
              </script>
            </wx-open-launch-weapp>
          </template>
          <div>
            <van-icon class-prefix="iconfont" :name="item.type" />
          </div>
					<div>{{item.name}}</div>
				</div>
			</div>
    </div>
    <!-- 活动HTML内容 -->
    <div class="htmlBody" :style="{paddingBottom: htmlbottom + 'px'}">
      <div v-if="articleData.content" v-html="articleData.content.body"></div>
    </div>
    <!-- 底部导航 -->
    <van-tabbar ref="footerTabbar" v-if="footer_nav.show" v-model="footerNavActive" @change="footerNavActiveChange" safe-area-inset-bottom :active-color="footer_nav.btnColor || '#646566'" :inactive-color="footer_nav.btnColor || '#646566'">
      <van-tabbar-item v-for="(item, index) in footer_nav.btns" :key="index" @click="clickHandle(item)" style="position: relative;">
        <template #icon>
          <van-icon class-prefix="iconfont" :name="item.type" />
        </template>
        <template v-if="item.type == 'wxApp'">
          <wx-open-launch-weapp
            :username="item.data"
            style="position: absolute; width: 100%; height: 100%; top: 0;left: 0;"
          >
            <script type="text/wxtag-template">
              <view style="position: absolute; width: 100%; height: 100%; top: 0;left: 0;"></view>	
            </script>
          </wx-open-launch-weapp>
        </template>
        {{ item.name }}
      </van-tabbar-item>
      <!-- <van-tabbar-item name="b" icon="search">标签</van-tabbar-item>
      <van-tabbar-item name="c" icon="friends-o">标签</van-tabbar-item>
      <van-tabbar-item name="d" icon="setting-o">标签</van-tabbar-item> -->
    </van-tabbar>

    <!-- 二维码弹窗 -->
    <van-dialog v-model="modal.qrCode.show" theme="round" :confirm-button-color="themeColor">
      <div class="modalQrCode">
        <img v-if="modal.qrCode.url" :src="modal.qrCode.url" width="100%" style="min-height: 180px"/>
        <div>{{ modal.qrCode.tips }}</div>
      </div>
    </van-dialog>

    <!-- 分享弹窗 -->
    <van-overlay :show="modal.share.show" @click="modal.share.show = false">
      <div class="wrapper" @click.stop>
        <img :src="modal.share.url" alt="" width="100%" @click="modal.share.show = false">
      </div>
    </van-overlay>

    <!-- 留资 -->
    <van-dialog 
      class="modalForm"
      v-model="modal.form.show" 
      theme="round-button"
      :confirm-button-text="'提交'"
      @confirm="confirmForm"
      @close="closeForm"
      :before-close="onBeforeClose"
      :confirm-button-color="themeColor"
    >
      <div slot="title" class="modalForm-title">{{ modal.form.title }}<van-icon name="close" @click="modal.form.show = false"/></div>
      <div style="padding: 15px 10px 30px">
        <van-cell-group :border="false">
          <van-field 
            v-model="modal.form.data.name"
            maxlength="11" 
            label="姓名" 
            label-width="50px" 
            placeholder="请输入姓名" 
            border/>
        </van-cell-group>
        <van-cell-group>
          <van-field 
            type="number" 
            v-model="modal.form.data.phone"
            maxlength="11" 
            label="手机号" 
            label-width="50px" 
            placeholder="请输入手机号" 
            border/>
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="modal.form.data.verify_code"
            center
            clearable
            label-width="50px" 
            label="验证码"
            placeholder="请输入短信验证码"
          >
            <template #button>
              <van-count-down v-show="showTime" :time="time" format="ss" ref="countDown" :auto-start="false" @finish="finish">
                <template #default="timeData">
                  <!-- <span class="block">{{ timeData.seconds  }}秒</span> -->
                  <van-button type="default" size="small">{{ timeData.seconds  }}秒后重试</van-button>
                </template>
              </van-count-down>
              <van-button v-show="!showTime" size="small" type="info" @click="getCode">
                发送验证码
              </van-button>
            </template>
          </van-field>
        </van-cell-group>
        <van-cell-group v-if="modal.form.car_columns.length">
          <van-field 
            readonly
            v-model="modal.form.data.intended_model"
            label="意向车型" 
            label-width="64px" 
            placeholder="请选择意向车型" 
            @click="modal.form.showCarPicker = true"
            border/>
        </van-cell-group>
        <van-cell-group v-if="modal.form.time_columns.length">
          <van-field 
            readonly
            v-model="modal.form.data.plan_time"
            label="购车时间" 
            label-width="64px" 
            placeholder="请选择购车时间" 
            @click="modal.form.showTimePicker = true"
            border/>
        </van-cell-group>
        <van-cell-group v-if="modal.form.follow_columns.length">
          <van-field 
            readonly
            v-model="modal.form.data.convenience_time"
            label="回访时间" 
            label-width="64px" 
            placeholder="方便接听电话时间" 
            @click="modal.form.showFollowPicker = true"
            border/>
        </van-cell-group>
      </div>
    </van-dialog>
    <van-popup v-model="modal.form.showCarPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="modal.form.car_columns"
        @cancel="modal.form.showCarPicker = false"
        @confirm="(index) => onConfirm(index, 'car')"
      />
    </van-popup>
    <van-popup v-model="modal.form.showTimePicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="modal.form.time_columns"
        @cancel="modal.form.showTimePicker = false"
        @confirm="(index) => onConfirm(index, 'time')"
      />
    </van-popup>
    <van-popup v-model="modal.form.showFollowPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="modal.form.follow_columns"
        @cancel="modal.form.showFollowPicker = false"
        @confirm="(index) => onConfirm(index, 'follow')"
      />
    </van-popup>
  </div>
</template>
<script>
import Vue from "vue"
import { Tabbar, TabbarItem, Icon, Dialog, Overlay, Cell, CellGroup, field, CountDown, Button, Popup, Picker, Toast  } from 'vant'
import { Image as VanImage } from 'vant'
import { articleSpreadShow, articleSpreadLeaveInfo, articleSpreadVisit, articleSpreadShare } from "@/api/articleSpread"
import { getVerifyCode, getSdkConfig } from "@/api/common"
import { mapActions, mapGetters } from 'vuex'
import storage from 'store'
import "@/assets/iconfont.css";
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Icon)
Vue.use(VanImage)
Vue.use(Dialog)
Vue.use(Overlay)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(field)
Vue.use(CountDown)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Toast)
export default {
  data () {
    return {
      themeColor: '#3690fd',
      footerNavActive: '',
      viewId: '',
      articleData: {},
      footer_nav: {},
      service_card: {},
      url: window.location.href,
      htmlbottom: 0,
      modal: {
        qrCode: {
          tips: '',
          show: false,
          url: ''
        },
        share: {
          show: false,
          url: ''
        },
        form: {
          show: false,
          data: {
            name: '',
            phone: '',
            verify_code: '',
            intended_model: '',
            plan_time: '',
            convenience_time: ''
          },
          showCarPicker: false,
          showTimePicker: false,
          showFollowPicker: false,
          car_columns: [],
          time_columns: [],
          follow_columns: []
        }
      },
      showTime: false,
      time: 60000,
    }
  },
  created () {
    this.cid = this.$route.query.cid || storage.get('cid')
    this.check_code = this.$route.query.check_code || storage.get('check_code')
    this.id = this.$route.query.id
    this.vcode = this.$route.query.vcode
    this.getOpenUserInfo()
    
  },
  mounted () {
    
  },
  methods: {
    ...mapActions(['Login']),
    // 授权
    getOpenUserInfo () {
      let that = this;
      that.Login({
        cid: that.cid,
        check_code: that.check_code
      }).then((res) => {
        if (!res.data.token) {
          let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code='+that.check_code+'&module=officialAccount&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl);
        } else {
          // 初始化jssdk, 获得接口权限
          getSdkConfig({
              url: window.location.href,
              apiList: ['openLocation', 'updateAppMessageShareData', 'updateTimelineShareData','onMenuShareAppMessage', 'onMenuShareTimeline'],
              openList: ['wx-open-launch-weapp']
          }).then(res => {
            this.$wx.init(res.data, this.getData())
          })
        }
      }).catch(res => {
        let redirectUrl = '/auth/officialAccount?cid='+that.cid+'&check_code='+that.check_code+'&module=officialAccount&target=' + encodeURIComponent(that.url);
        that.$redirectAuth(redirectUrl);
      })
    },
    // 获取活动详情
    getData () {
      if(this.id){
        articleSpreadShow({
          id: this.id,
          vcode: this.vcode
        }).then(res => {
          this.articleData = res.data
          this.footer_nav = res.data.content.footer_nav
          this.service_card = res.data.content.service_card

          // 微信浏览器初始化后不再监听title change事件
          var tdom = document.createElement('iframe');
          tdom.src = '';
          tdom.style.display = 'none';
          tdom.onload = function() {
              setTimeout(function(){
                  tdom.remove();
              }, 9)
          }
          document.title = this.articleData.title
          document.body.appendChild(tdom);

          // 获取内容第一张图片
					if(this.articleData.content && this.articleData.content.body){
						this.articleData.content.body.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/i, (match,capture) => {
							this.firstImg = capture
						});

            // 获取底部tab高度作为下边距防止内容遮盖
            setTimeout(() => {
              this.htmlbottom = document.getElementsByClassName('van-tabbar')[0].clientHeight
            })
           
					}

          // 获取分享链接和长链socket_token 
          articleSpreadVisit({
            id: this.id,
            vcode: this.vcode
          }).then(visit => {
            // this.socket_token = visit.data.socket_token
            this.viewId = visit.data.view_id
            this.initSocket(visit.data.socket_token, visit.data.view_id)

            // 设置分享
           this.$wx.share({
							title: this.articleData.title,
							desc: this.articleData.desc,
							imgUrl: this.articleData.thumbnail,
              link: visit.data.share_url,
              success: (shareStatus) => {
                let shareType = 1
                if (shareStatus && shareStatus.errMsg) {
                  // 分享给朋友或群
                  if (shareStatus.errMsg.includes('sendAppMessage')) {
                    shareType = 1
                  } 
                  // 分享到朋友圈
                  if (shareStatus.errMsg.includes('shareTimeline')) {
                    shareType = 2
                  }
                  // 请求服务器
                  articleSpreadShare({
                    view_id: this.viewId,
                    share_type: shareType
                  })
                }
              }
						})
            
          })

        })
      }
    },
    // 抵消vant tabbar组件自动选中
    footerNavActiveChange () {
      this.footerNavActive = ''
    },
    // 按钮点击
    clickHandle (item) {
      switch (item.type) {
        case 'call':
          if (item.data.type == 2 && item.data.value) {
            window.location.href= 'tel://' + item.data.value
          } else if(this.articleData.employee.mobile) {
            window.location.href= 'tel://' + this.articleData.employee.mobile
          }
          break
        case 'wxCode':
          if (item.data) {
            this.modal.qrCode.url = item.data
            this.modal.qrCode.tips = '长按二维码，关注公众号'
            this.modal.qrCode.show = true
          }
          break
        case 'location':
          this.$wx.openLocation(item.data)
          break
        case 'share':
          if (item.data) {
            this.modal.share.url = item.data
          } else {
            this.modal.share.url = require('@/static/images/share_tips.png')
          }
          this.modal.share.show = true
          break
        case 'qwCode':
          if (item.data.type == 2 && item.data.value) {
            this.modal.qrCode.url = item.data.value
          } else {
            this.modal.qrCode.url = this.articleData.employee.qrCode
          }
          this.modal.qrCode.tips = '长按二维码，加企业微信'
          this.modal.qrCode.show = true
          break
        case 'form':
          if (item.data.showModel && item.data.model) {
            this.modal.form.car_columns = item.data.model
          }
          if (item.data.showTime && item.data.time) {
            this.modal.form.time_columns = item.data.time
          }
          if (item.data.showFollow && item.data.follow) {
            this.modal.form.follow_columns = item.data.follow
          }
          this.modal.form.title = item.name
          this.modal.form.show = true
          break
        case 'service':
          console.log(this.$checkUrl(item.data))
          this.$checkUrl(item.data)?window.location = item.data : ''
          break
      }
    },
    getCode () {
      if(!this.modal.form.data.phone || !(/^1[3456789]\d{9}$/.test(this.modal.form.data.phone))){
        Toast('请正确填写手机号码')
        return
      }
      this.showTime = true
      this.$refs.countDown.start()
      getVerifyCode({
        phone: this.modal.form.data.phone
      }).then(res => {
        Toast('验证码已发送')
      })
    },
    finish () {
      this.showTime = false
      this.$refs.countDown.reset()
    },
    onConfirm (index, type) {
      console.log(index)
      switch (type) {
        case 'car':
          this.modal.form.data.intended_model = index
          this.modal.form.showCarPicker = false
          break
        case 'time':
          this.modal.form.data.plan_time = index
          this.modal.form.showTimePicker = false
          break
        case 'follow':
          this.modal.form.data.convenience_time = index
          this.modal.form.showFollowPicker = false
          break
      }
    },
    closeForm () {
      this.modal.form.data.name = ''
      this.modal.form.data.phone = ''
      this.modal.form.data.verify_code = ''
      this.modal.form.data.intended_model = ''
      this.modal.form.data.plan_time = ''
      this.modal.form.data.convenience_time = ''
      this.modal.form.showCarPicker = false
      this.modal.form.showTimePicker = false
      this.modal.form.car_columns = []
      this.modal.form.time_columns = []
      this.modal.form.follow_columns = []
    },
    confirmForm () {
      if(!this.modal.form.data.name){
        return Toast('请填写名称')
      }
      if(!this.modal.form.data.phone || !(/^1[3456789]\d{9}$/.test(this.modal.form.data.phone))){
        return Toast('请正确填写手机号')
      }
      if(!this.modal.form.data.verify_code){
        return Toast('请填写短信验证码')
      }
      if(this.modal.form.car_columns.length && !this.modal.form.data.intended_model){
        return Toast('请选择意向车型')
      }
      if(this.modal.form.time_columns.length && !this.modal.form.data.plan_time){
        return Toast('请选择购车时间')
      }
      if(this.modal.form.follow_columns.length && !this.modal.form.data.convenience_time){
        return Toast('请选择购车时间')
      }
      articleSpreadLeaveInfo({
        ...this.modal.form.data,
        view_id: this.viewId
      }).then(res => {
        if (res.code == 200) {
          this.modal.form.show = false
          Toast('提交成功！')
        } else {
          Toast(res.msg)
        }
      })
    },
    // 阻止vant Dialog 组件点确认后自动关闭 
    onBeforeClose (action, done) {
      if(action === 'confirm'){
        return done(false)
      }else{
        done()
      }
    },
    // 建立连接
    initSocket(token, viewId) {
        // 有参数的情况下：
        var domain = process.env.VUE_APP_API_BASE_URL
        let url = `wss:${domain}/socket/?token=${token}&type=article&article_read_id=${viewId}`
        // 没有参数的情况:接口
        // let url1 = 'ws://localhost:9998'
        this.webSocket = new WebSocket(url)
        this.webSocket.onopen = this.webSocketOnOpen
        this.webSocket.onclose = this.webSocketOnClose
        this.webSocket.onmessage = this.webSocketOnMessage
        this.webSocket.onerror = this.webSocketOnError

    },
    // 建立连接成功后的状态
    webSocketOnOpen() {
        console.log('websocket连接成功');
    },
    // 获取到后台消息的事件，操作数据的代码在onmessage中书写
    webSocketOnMessage(res) {
        // res就是后台实时传过来的数据
          //给后台发送数据
        // this.webSocket.send("发送数据");
    },
    // 关闭连接
    webSocketOnClose() {
        this.webSocket.close()
        console.log('websocket连接已关闭');
    },
    //连接失败的事件
    webSocketOnError(res) {
        console.log('websocket连接失败');
        // 打印失败的数据
        console.log(res);
    },
  }
}
</script>

<style scoped lang="less">
/deep/.htmlBody {
  h1, h2, h3, h4, h5, p {
    margin-bottom: 0;
  }
  img, video {
    vertical-align: middle;
  }

  video {
    position: relative;
  }
}
.htmlBody ::v-deep img {
  max-width: 100%;
}
.sale {
	// padding: 20upx 30upx 0;
	// margin: 0 20upx 20upx;
	position: relative;
	margin-bottom: 6px;
	background-image: linear-gradient(to right bottom , #67a9fa, #3690fd);
	// border-radius: 0 0 30upx 30upx;
	overflow: hidden;
	color: #f8f8f8;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
	
	&-bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		// background-color: #fff;
		background-size: 100% auto;
		filter: blur(16px);
		transform: scale(1.8);
	}
	
	&-intro {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		padding: 20px;
		
		&-text {
			margin-left: 15px;
			font-size: 18px;
			
			div:last-child {
				// margin-top: 10px;
				font-size: 14px;
				// color: #999;
				
			}
		}
	}
	
	&-bar {
		position: relative;
		z-index: 1;
		display: flex;
		// padding-bottom: 20px;

		&-item {
			position: relative;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px 10px;
			
			div:first-child i {
				font-size: 20px;
			}
			
			div:last-child {
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}
	
}
.modalQrCode {
  padding: 40px 20px;
  text-align: center;
  img {
    width: 80%;
    margin-bottom: 10px;
  }
}
.modalForm {
  /deep/.van-dialog__header {
    padding-top: 15px;
  }

  &-title {
    position: relative;

    i {
      position: absolute;
      right: 10px;
      font-size: 24px;
      color: #999;
      
    }
  }
}
</style>