var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"graphicnavigation",style:({ backgroundImage: 'url(' + _vm.datas.bgImg + ')' })},[(!_vm.datas.imageList[0])?_c('section',{staticClass:"defaultNavigation",style:({
      background: _vm.datas.backgroundColor,
      display: _vm.datas.imgStyle === 0 ? 'flex' : '-webkit-box',
      'overflow-x': _vm.datas.imgStyle === 0 ? '' : 'scroll',
    })},_vm._l((5),function(index){return _c('div',{key:index,staticClass:"navigationList",style:({
        width:
          _vm.datas.imgStyle === 0 ? 'auto' : 375 / _vm.datas.showSize - 1 + 'px',
      })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.navigationType === 0),expression:"datas.navigationType === 0"}],style:({ 'border-radius': _vm.datas.borderRadius + '%' }),attrs:{"src":require("../../../assets/as/imgs.png"),"alt":"默认图片","draggable":"false"}}),_c('p',{style:({ color: _vm.datas.textColor })},[_vm._v("导航")])])}),0):_c('section',{staticClass:"defaultNavigation",style:({
      background: _vm.datas.backgroundColor,
      display: _vm.datas.imgStyle === 0 ? 'flex' : '-webkit-box',
      'flex-wrap': _vm.datas.imgStyle === 0 ? 'wrap' : 'nowrap',
      'justify-content':
        _vm.datas.imgStyle === 0 ? 'space-evenly' : 'space-around',
      'overflow-x': _vm.datas.imgStyle === 0 ? '' : 'scroll',
    })},_vm._l((_vm.datas.imageList),function(item,index){return _c('div',{key:index,staticClass:"navigationList",style:({
        width: _vm.datas.imgStyle === 0 ? '20%' : 375 / _vm.datas.showSize - 1 + 'px',
      })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.navigationType === 0),expression:"datas.navigationType === 0"}],style:({ 'border-radius': _vm.datas.borderRadius + '%' }),attrs:{"src":item.src,"alt":"默认图片","draggable":"false"}}),_c('p',{style:({
          color: _vm.datas.textColor,
          'font-size': _vm.datas.textSize + 'px',
          height: _vm.datas.textHeight + 'px',
          'line-height': _vm.datas.textHeight + 'px',
        })},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0),_vm._t("deles")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }