<template>
  <div class="shopDetail">
    <!--  -->
    <van-swipe class="shop-swipe" :autoplay="3000" @change="swChange">
      <van-swipe-item v-for="(image, index) in shopData.slider_image" :key="index">
        <!-- <img v-lazy="image" /> -->
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="image"
          fit="cover"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </van-swipe-item>
      <template #indicator v-if="shopData.slider_image">
        <div class="custom-indicator">{{ current+1 }}/{{ shopData.slider_image.length }}</div>
      </template>
    </van-swipe>

    <!--  -->
    <div class="shop-detail">
      <van-skeleton title :row="2" :loading="loading" style="margin: 20px 0;">
        <div class="shop-detail-list">
          <div class="price" v-if="shopData.price">￥<span>{{ shopDisPrice ? shopData.dis_price : shopData.price }}</span><s v-if="shopDisPrice">{{ shopData.price }}</s></div>
          <!-- <div class="num">库存{{ shopData.stock }}</div> -->
        </div>
        <div class="shop-detail-list" style="padding-bottom: 15px; margin-bottom: 12px">
          <div class="title">{{ shopData.store_name }}</div>
          <div class="decs">{{ shopData.store_info }}</div>
        </div>
      </van-skeleton>

      <van-cell-group v-if="!loading">
        <van-cell class="serviceList" :value="'库存'+shopData.stock">
          <template #title>
            <span class="custom-title">服务</span>
            <span>{{shopData.trade_type_text}}</span>
          </template>
        </van-cell>
      </van-cell-group>

      <div class="contentTitle" v-if="!loading">商品详情</div>
      <div class="shop-detail-content" v-html="shopData.description">
      </div>
    </div>

    <!--  -->
    <van-goods-action v-if="shopData.trade_type == 2">
      <!-- <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" />
      <van-goods-action-icon icon="cart-o" text="购物车" @click="onClickIcon" />
      <van-goods-action-icon icon="shop-o" text="店铺" @click="onClickIcon" /> -->
      <van-goods-action-button
        type="warning"
        text="立即购买"
        @click="onClickButton"
      />
    </van-goods-action>
    <van-goods-action v-if="shopData.trade_type == 1">
      <!-- <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" />
      <van-goods-action-icon icon="cart-o" text="购物车" @click="onClickIcon" />
      <van-goods-action-icon icon="shop-o" text="店铺" @click="onClickIcon" /> -->
      <van-goods-action-button
        type="warning"
        text="我要咨询"
        @click="showFrom = true"
      />
    </van-goods-action>

    <!-- 留资弹窗 -->
    <van-dialog 
        v-model="showFrom" 
        show-cancel-button 
        confirm-button-text="咨询" 
        @confirm="confirmForm"
        :before-close="onBeforeClose"
    >
        <div style="padding: 30px 10px">
            <van-cell-group :border="false">
                <van-field 
                    v-model="form.name" 
                    label="姓名" 
                    left-icon="user-o" 
                    label-width="4rem" 
                    placeholder="请输入姓名" 
                    border/>
                    <van-field 
                    type="number" 
                    maxlength="11" 
                    v-model="form.phone" 
                    label="电话" 
                    left-icon="phone-o" 
                    label-width="4rem" 
                    placeholder="请输入手机号" 
                    border/>
            </van-cell-group>
        </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from 'vue';
import { Dialog, Toast, GoodsAction, GoodsActionIcon, GoodsActionButton, Swipe, SwipeItem, Lazyload, CellGroup, Field, Cell, CellGrou, Image as VanImage, Loading, Skeleton  } from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Lazyload);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Skeleton)

import { h5ProductShow, launchOrder } from '@/api/shop'
import { getSdkConfig } from "@/api/common"
import storage from 'store'

export default {
  data () {
    return {
      id: '',
      current: 0,
      bottomHeight: 0,
      shopData: {
        stock: 0,
        trade_type_text: ''
      },

      showFrom: false,
      form: {},
      loading: true,
      shopDisPrice: false
    }
  },
  created () {

    console.log(this.$route.query.id)
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      h5ProductShow({
        id: this.id
      }).then(res => {
        if (res.data) {
          res.data.slider_image = res.data.slider_image.split(',')
          this.shopData = res.data
          if (this.shopData.dis_price != '0.00') {
            this.shopDisPrice = true
          }
          this.loading = false
          // 设置分享
          // 初始化jssdk, 获得接口权限
          getSdkConfig({
            url: window.location.href,
            apiList: ['updateAppMessageShareData', 'updateTimelineShareData','onMenuShareAppMessage', 'onMenuShareTimeline'],
          }).then(sdk => {
            this.$wx.init(sdk.data, () => {
               // 设置分享
              this.$wx.share({
                  title: this.shopData.store_name,
                  desc: this.shopData.store_info,
                  imgUrl: this.shopData.image + '?x-oss-process=image/resize,w_200/quality,q_90',
                  link: window.location.host + '/login?t_url=' + encodeURIComponent(window.location.pathname + window.location.search + '&cid=' + storage.get('cid')),
                  success: (shareStatus) => {
                  }
              })
            })
          })
        }
      })
    }
  },
  mounted () {
    // this.bottomHeight = document.getElementsByClassName('van-goods-action')[0].clientHeight
    console.log(this.$route)
  },
  methods: {
    onClickIcon () {},
    onClickButton () {
      // this.$router.go(-1)
      this.$router.push({path: '/shop/confirmOrder', query: {
        pid: this.shopData.id,
      }})
    },
    swChange(index) {
      this.current = index
    },
    confirmForm () {
      if(!this.form.name){
        Toast('请填写姓名')
        return
      }
      if(!this.form.phone || !(/^1[3456789]\d{9}$/.test(this.form.phone))){
        Toast('请正确填写手机号码')
        return
      }
      let params = {
        id: this.id,
        real_name: this.form.name,
        user_phone: this.form.phone,
      }
      launchOrder(params).then(res => {
        if (res.data && res.data.order_sn) {
          Toast({
            type: 'success',
            message: '咨询成功',
          })
          this.showFrom = false
        }
      })
    },
    onBeforeClose (action, done) {
      if(action === 'confirm'){
          return done(false)
      }else{
          done()
      }
    },
  }
}
</script>
<style scoped lang="less">
:root{ 
  color: rgba(255, 140, 0, 1);
}

.shopDetail {
  background: #f9f9f9;
  min-height: 100vh;
  padding-bottom: 50px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 50px);//兼容 IOS<11.2
  padding-bottom: calc(env(safe-area-inset-bottom) + 50px);//兼容 IOS>11.2

  .shop-swipe {
    width: 100%;
    height: 100vw;
    background: #f1f1f1;

     .custom-indicator {
        position: absolute;
        right: 5px;
        bottom: 5px;
        padding: 2px 5px;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.1);
      }

    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      line-height: 150px;
      text-align: center;

      img {
        height: 100%;
      }
    }
  }

  .shop-detail {

    // /deep/.van-cell__title {
    //   width: 40px;
    //   flex: none;
    //   color: #999;
    // }

    // /deep/.van-cell__value {
    //   text-align: left;
    //   color: #585858;
    // }

    .serviceList {
      .custom-title {
        margin-right: 10px;
        color: #999;
      }
    }

    .shop-detail-list {
      padding: 15px 15px 10px;
      background: #fff;
    }

    .shop-detail-list:first-child {
      display: flex;
      align-items: center;
      padding-bottom: 0;
    }

    .price {
      color: rgb(255, 140, 0);
      flex: 1;
      span {
        font-size: 22px;
        font-weight: 600;
      }
      
      s {
        color: #999;
        margin-left: 6px;
      }
    }


    .title {
      background-color: #fff;
      font-size: 15px;
      margin-bottom: 6px;
    }

    .decs {
      padding-top: 5px;
      line-height: 16px;
      font-size: 14px;
      color: #bbb;
    }

  }

  .contentTitle {
    margin-top: 12px;
    background: #fff;
    text-align: center;
    line-height: 36px;
    font-size: 12px;
    color: #bbb;
  }

  .shop-detail-content {
    // margin-top: 10px;
    
    /deep/img {
      max-width: 100%;
    }
  }
}
</style>