<template>
  <div class="confirmOrder">
    <div class="main">
      <van-cell-group>
        <van-field v-model="name" label="姓名" placeholder="请输入姓名"  input-align="right"/>
        <van-field v-model="phone" maxlength="11" label="手机" placeholder="请输入手机"  input-align="right"/>
      </van-cell-group>
      <van-card
        class="product"
        num="1"
        :price="countPrice"
        :desc="pData.store_info"
        :title="pData.store_name"
        :thumb="pData.image + '?x-oss-process=image/resize,w_250/quality,q_60'"
      />
      <van-radio-group v-model="playType">
        <van-cell-group>
          <van-cell :icon="wxPlay" title="微信支付" clickable @click="radio = '1'">
            <template #right-icon>
              <van-radio name="1" checked-color="#07C160"/>
            </template>
            <template #icon>
              <van-image :src="wxPlay" height="24" width="24" style="margin-right: 6px"></van-image>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-cell-group>
        <van-field v-model="remark" label="留言" placeholder="建议100字以内" input-align="right"/>
      </van-cell-group>
    </div>
    <van-submit-bar button-color="#ff8c00" :price="countPrice * 100" button-text="提交订单" :loading="loading" @submit="onSubmit" />
  </div>
</template>
<script>
import { myOrderIndex, h5ProductShow, launchOrder, placeOrder } from '@/api/shop'
import Vue from 'vue';
import { Image as VanImage, SubmitBar, Card, Field, RadioGroup, Radio, Cell, CellGroup, Toast   } from 'vant';
Vue.use(VanImage);
Vue.use(SubmitBar);
Vue.use(Card);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Toast);
import wxPlay from '@/static/images/wxPlay.png'
import { getSdkConfig } from '@/api/common'
export default  {

  data() {
    return {
      wxPlay,
      loading: false,
      playType: '1',
      remark: '',
      name: '',
      phone: '',
      pid: '',
      pData: {},
      countPrice: 0,
      shopDisPrice: false
    }  
  },
  created () {
    if (this.$route.query.pid) {
      this.pid = this.$route.query.pid
      h5ProductShow({
        id: this.pid
      }).then(res => {
        if (res.data) {
          this.pData = res.data
          if (this.pData.dis_price != '0.00') {
            this.shopDisPrice = true
            this.countPrice = this.pData.dis_price
          } else {
            this.countPrice = this.pData.price
          }
        }
      })
    }
  },
  methods: {
    onSubmit () {
      if (this.pData.trade_type == 2) {
        if (!this.name) return Toast('请填写姓名')
        if (!this.phone || this.phone.length != 11) return Toast('请正确填写手机')
        let params = {
          id: this.pid,
          real_name: this.name,
          user_phone: this.phone,
          remark: this.remark
        }
        launchOrder(params).then(res => {
          if (res.data && res.data.order_sn) {
            Toast.loading({
              message: '支付中'
            })
            var origin = window.location.origin;
            var a = origin.substr(5).search(":");
            var uri = origin + window.location.pathname;

            getSdkConfig({
              url: uri,
              apiList: ['chooseWXPay']
            }).then((sdkData) => {
              // 初始化
              this.$wx.init(sdkData.data, () => {
                placeOrder({
                  order_sn: res.data.order_sn
                }).then(order => {
                  Toast.clear()
                  this.$wx.pay(
                    order.data, 
                    payRes => {
                      // 成功回调
                      Toast({
                        type: 'success',
                        message: '支付成功',
                        onClose: () => {
                          this.$router.replace({
                            path: '/shop/orderList',
                          })
                        }
                      })
                    },
                    payRes => {
                      // 取消回调
                      Toast({
                        type: 'fail',
                        message: '支付已取消',
                        onClose: () => {
                          this.$router.replace({
                            path: '/shop/orderList',
                          })
                        }
                      })
                    },
                    payRes => {
                      // 失败回调
                      Toast({
                        type: 'fail',
                        message: '支付失败',
                        onClose: () => {
                          this.$router.replace('/shop/orderList')
                        }
                      })
                    }
                  )
                })
              })
            })

            
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.confirmOrder {
  background-color: #f5f5f5;
  min-height: calc(100vh);
  padding-bottom: 50px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 50px);//兼容 IOS<11.2
  padding-bottom: calc(env(safe-area-inset-bottom) + 50px);//兼容 IOS>11.2
  
  .main {
    padding: 12px;

    /deep/.product {
      margin-bottom: 12px;
      background: #fff;
      border-radius: 6px;
      padding: 8px;

      .van-card__desc {
        font-size: 12px;
      }
    }

    /deep/.van-cell-group {
      margin-bottom: 12px;
      border-radius: 6px;
      overflow: hidden;
    }
  }
}

</style>