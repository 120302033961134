<template>
  <div class="productList">
    <van-search v-model="searchValue" placeholder="搜索商品名称" @search="tabChange" @clear="tabChange"/>
    <!-- <van-dropdown-menu z-index="1">
      <van-dropdown-item v-model="value1" :options="option1" />
      <van-dropdown-item v-model="value2" :options="option2" />
    </van-dropdown-menu> -->
    <van-tabs :color="$themeColor" v-model="statusActive" @change="tabChange">
      <van-tab v-for="(item, index) in orderStatus" :title="item.title" :key="index">
      </van-tab>
    </van-tabs>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="list">
        <van-list
          style="padding: 12px 6px"
          v-model="loading"
          :finished="finished"
          @load="onLoad"
        >
          <div class="list-item" v-for="(item, index) in list" :key="index" >
            <div class="list-item-orderNum"><span>{{item.order_no}}</span><van-tag plain :color="$themeColor">{{ item.status_text }}</van-tag></div>
            <van-card
              class="list-item-product"
              :num="1"
              :price="item.product.dis_price != '0.00' ? item.product.dis_price : item.product.price"
              :title="item.product.store_name"
              :thumb="item.product.image + '?x-oss-process=image/resize,w_250/quality,q_60'"
              @click="goOrder(item.id)"
            />
            <div class="list-item-orderPrice" v-if="item.type == 2">
              实付 <span :style="{color: $themeColor}">￥<span>{{item.pay_price}}</span></span>
            </div>
            <div class="list-item-operation" v-if="item.status == 6">
              <van-button :color="$themeColor" round size="small" @click="pay(item.order_no)">支付订单</van-button>
            </div>
          </div>
          <van-empty v-if="!list.length && finished" description="暂无订单" />
          <div slot="finished" v-if="list.length && finished">暂无更多</div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { myOrderIndex, placeOrder } from '@/api/shop'
import { getSdkConfig } from '@/api/common'
import Vue from 'vue';
import { Image as VanImage, Search, PullRefresh, DropdownMenu, DropdownItem, List, Empty, Tab, Tabs, Tag, Card, Button, Toast } from 'vant';
Vue.use(VanImage);
Vue.use(Search);
Vue.use(PullRefresh);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(List);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Card);
Vue.use(Button);
export default  {
  beforeRouteEnter(to,from,next) {
    if (from.path == '/shop/orderDetail') {
      next()
    } else {
      next((vm) => {
        // if (vm.$route.query.type) {
        //   for (var i = 0; i < vm.orderStatus.length; i++) {
        //     if (vm.orderStatus[i].value == vm.$route.query.type) {
        //       vm.statusActive = i
        //       break
        //     }
        //   }
        // }
        vm.searchValue = ''
        vm.statusActive = ''
        vm.tabChange()
      })
    }
    
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      finished: false,
      list: [],
      statusActive: '',
      searchValue: '',

      value: '',
      value1: 0,
      value2: 'a',
      option1: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
      option2: [
        { text: '默认排序', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        count: 0
      },
      orderStatus: [
        //  -1=申请退款；-2=退货成功；0=待发货；1=待收货；2=已收货；3=待评价；4=待核销；5=已核销；9=已退款
        {
          title: '全部',
          value: ''
        },
        {
          title: '待付款',
          value: '6'
        },
        {
          title: '待自提',
          value: '4'
        },
        {
          title: '已自提',
          value: '5'
        },
        {
          title: '申请退款',
          value: '-1'
        },
        {
          title: '已退款',
          value: '9'
        },
        // {
        //   title: '退货成功',
        //   value: '-2'
        // },
        // {
        //   title: '待发货',
        //   value: '0'
        // },
        // {
        //   title: '待收货',
        //   value: '1'
        // },
        // {
        //   title: '已收货',
        //   value: '2'
        // },
        // {
        //   title: '待评价',
        //   value: '3'
        // },
        
      ]
    }  
  },
  created () {
    if (this.$route.query.type) {
      for (var i = 0; i < this.orderStatus.length; i++) {
        if (this.orderStatus[i].value == this.$route.query.type) {
          this.statusActive = i
          break
        }
      }
    }
  },
  methods: {
    goOrder (id) {
      this.$router.push({
        path: '/shop/orderDetail',
        query: {
          id
        }
      })
    },
    pay (code) {
      Toast.loading({
        message: '支付中'
      })
      var origin = window.location.origin;
      var a = origin.substr(5).search(":");
      var uri = origin + window.location.pathname;
      getSdkConfig({
        url: uri,
        apiList: ['chooseWXPay']
      }).then(res => {
        // 初始化
        this.$wx.init(res.data, () => {
          placeOrder({
            order_sn: code
          }).then(order => {
            Toast.clear()
            this.$wx.pay(
              order.data, 
              payRes => {
                // 成功回调
                Toast({
                  type: 'success',
                  message: '支付成功',
                  onClose: () => {
                    this.tabChange()
                  }
                })
                // alert(JSON.stringify(payRes))
              },
              payRes => {
                // 取消回调
                Toast({
                  type: 'fail',
                  message: '支付已取消',
                  onClose: () => {
                    this.tabChange()
                  }
                })
                // alert(JSON.stringify(payRes))
              },
              payRes => {
                // 失败回调
                Toast.fail('支付失败')
                // alert(JSON.stringify(payRes))
              }
            )
          })
        })
      })
    },
    tabChange (v) {
      this.pagination.current = 1
      this.list = []
      this.finished = false
      this.onLoad()
    },
    onRefresh () {
      this.pagination.current = 1
      this.list = []
      this.isLoading = false
      this.finished = false
      this.onLoad()
    },
    onLoad () {
      this.loading = true
      let params = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      }
      if (this.statusActive) {
        params.status = this.orderStatus[this.statusActive].value
      }
      if (this.searchValue) {
        params.store_name = this.searchValue
      }
      myOrderIndex(params).then(res => {
        if (res.code == 200) {
          this.list = this.list.concat(res.data.lists.data)
        }
        this.loading = false
        this.isLoading = false

        if (this.list.length == res.data.lists.total )  {
          this.finished = true
        } else {
          this.pagination.current++
        }
      }, () => {
        this.finished = true
        this.loading = false
        this.isLoading = false
      })
      
    }
  }
}
</script>

<style scoped lang="less">
.productList {
  background-color: #f5f5f5;
}
.list {
  min-height: calc(100vh - 54px - 44px);  
  background-color: #f5f5f5;

  &-item {
    margin: 0 6px 12px;
    background-color: #fff;
    border-radius: 6px;

    &-orderNum {
      padding: 8px;
      display: flex;
      justify-content: space-between;
    }

    /deep/&-product {
      margin-top: 0 !important;
      background-color: #fff;
      padding: 8px 10px;
    }

    &-orderPrice {
      padding: 10px;
      text-align: right;
      padding-right: 10px;

      >span>span {
        font-size: 18px;
      }
    }

    &-operation {
      padding: 10px;
      text-align: right;
      padding-right: 10px;
      border-top: .5px solid #f5f5f5;
    }
    
    // .intro {
    //   background-color: #fff;
    //   padding: 10px;

    //   &-title {
    //     margin-bottom: 20px;
    //     line-height: 16px;
    //     overflow: hidden;
    //     -webkit-line-clamp: 2;
    //     text-overflow: ellipsis;
    //     display: -webkit-box;
    //     -webkit-box-orient: vertical;
    //   }

    //   &-price {
    //     color: #ff8c00;
    //   }

    //   &-num {
    //     font-size: 12px;
    //     color: #999;
    //   }
    // }

    // .image {
    //   width: 100%;
    //   padding-top: 100%;
    //   background-size: cover;
    //   background-position: center;
    // }
  }
}



</style>