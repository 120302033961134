var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"headerTop",staticStyle:{"position":"fixed"},style:({
      height: _vm.name.titleHeight + 'px'
    })},[(_vm.name.isBack)?_c('div',{staticClass:"lef",on:{"click":_vm.pushHome}},[_c('van-icon',{attrs:{"name":"arrow-left"}})],1):_vm._e(),_c('div',{staticClass:"header-title",style:({
        height: _vm.name.titleHeight + 'px',
        'line-height': _vm.name.titleHeight + 'px'
      })},[_vm._v(" "+_vm._s(_vm.name.name ? _vm.name.name : '')+" ")]),(_vm.name.isPerson)?_c('div',{staticClass:"rig",on:{"click":function($event){return _vm.$router.push({ name: _vm.name.urlname ? _vm.name.urlname : 'personal' })}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.name.subhead ? _vm.name.subhead : '个人中心')}})]):_vm._e()]),_c('div',{style:({
      height: _vm.name.titleHeight + 'px'
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }