var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"captiontext",style:({ background: _vm.datas.backColor })},[_c('div',{staticStyle:{"padding":"6px 0"},style:({
      'border-bottom': _vm.datas.borderBott
        ? '1px solid #F9F9F9'
        : '1px solid #fff',
    })},[(_vm.datas.name)?_c('h2',{style:({
        'font-size': _vm.datas.wordSize + 'px',
        'font-weight': _vm.datas.wordWeight,
        color: _vm.datas.wordColor,
        'text-align': _vm.datas.positions,
        height: _vm.datas.wordHeight + 'px',
        'line-height': _vm.datas.wordHeight + 'px',
        'padding-right': !(_vm.datas.positions !== 'center' && _vm.datas.more.show)
          ? '0'
          : '60px',
      })},[_vm._v(" "+_vm._s(_vm.datas.name)+" ")]):_vm._e(),(_vm.datas.description)?_c('p',{staticStyle:{"margin-top":"8px"},style:({
        'font-size': _vm.datas.descriptionSize + 'px',
        'font-weight': _vm.datas.descriptionWeight,
        color: _vm.datas.descriptionColor,
        'text-align': _vm.datas.positions,
      })},[_vm._v(" "+_vm._s(_vm.datas.description)+" ")]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.more.show),expression:"datas.more.show"}],staticClass:"more",class:_vm.datas.positions !== 'center' ? 'lef' : '',style:({
        color: _vm.datas.more.type === 0 ? '#38f' : '',
        top: (_vm.datas.wordHeight - 6) / 2 + 'px',
      })},[_vm._v(" "+_vm._s(_vm.datas.more.type === 2 ? '' : _vm.datas.more.text)+" "),_c('span',[_vm._v(" "+_vm._s(_vm.datas.more.type === 0 ? '' : '>'))])])]),_vm._t("deles")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }