import { request } from "../plugins/axios"

// 智慧传播详情
export function articleSpreadShow (params) {
  return request({
      url: '/articleSpread/show',
      method: 'GET',
      params: params
  })
}

// 留资
export function articleSpreadLeaveInfo (params) {
  return request({
      url: '/articleSpread/leaveInfo',
      method: 'POST',
      data: params
  })
}

// 获取soketToken
export function articleSpreadVisit (params) {
  return request({
      url: '/articleSpread/visit',
      method: 'GET',
      params
  })
}

// 分享记录
export function articleSpreadShare (params) {
  return request({
      url: '/articleSpread/share',
      method: 'POST',
      data: params
  })
}