var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auxiliarysegmentation"},[_c('section',{staticClass:"contan",style:({
      height: _vm.datas.blankHeight + 'px',
      padding: _vm.datas.paddType === 0 ? '0px' : '0px 15px',
    })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.datas.segmentationtype === 1),expression:"datas.segmentationtype === 1"}],staticStyle:{"height":"1px","width":"100%","border-top-width":"1px"},style:({
        'border-top-style': _vm.datas.bordertp,
        'border-top-color': _vm.datas.auxliarColor,
      })})]),_vm._t("deles")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }